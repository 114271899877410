import { useContext, useEffect, useMemo, useState } from 'react';
import { formatTimezoneDate } from '@/lib/formatter';
import { TrackingPointCascadeType } from 'app/modules/inspection/inspection.interfaces';
import SettingsStore from 'app/modules/settings/settings.context';
import InspectionStore from 'app/modules/inspection/inspection.context';
import AccountStore from 'app/modules/account/account.context';
import { AuthType } from 'app/modules/account/account.context.d';
import { TriggerType } from 'app/modules/detection/detection.interfaces';
import SatellitePassages from '../../../../components/satellite-passages/satellite-passages';
import Typograph from 'stories/type/typograph/typograph';
import Overlay from 'stories/layout/overlay/overlay';
import Charts from 'app/modules/inspection/run/components/charts/charts';
import { getMediaUrl, getTriggerTstamp } from 'app/modules/inspection/run/devices/desktop/components/qube-triggers-overlay/qube-triggers-overlay.controller';
import {
  AGMTitle,
  DeviceSerialNumber,
  DownloadWrapper,
  MenuButton,
  MenuListItem,
  MenuListWrapper,
  QubeTriggersOverlayContent,
  QubeTriggersOverlayContentWrapper,
  QubeTriggersOverlayPresetContainer,
  QubeTriggersOverlaySidebar,
} from './qube-triggers-overlay.style';

/**
 * this component should render floating the run
 * in the right side, showing all informations triggers
 * captured by qube
 */
const QubeTriggersOverlay = () => {
  const settingsContext = useContext(SettingsStore);
  const inspectionContext = useContext(InspectionStore);
  const accountContext = useContext(AccountStore);
  const auth = accountContext.state.auth as AuthType;
  
  const agm = useMemo(() => inspectionContext.state.selected_point_triggers as TrackingPointCascadeType, [inspectionContext.state.selected_point_triggers]);
  const trigger = useMemo(() => inspectionContext.state.selected_trigger_passage as TriggerType, [inspectionContext.state.selected_trigger_passage]);
  
  const [showMenu, setShowMenu] = useState<boolean>(false);

  useEffect(() => {
    const approved = agm.trigger_set?.find(
      ({ processed, passage }) => processed && passage
    );

    const rejected = agm.trigger_set?.filter(
      ({ processed, passage }) => processed && !passage
    );

    const toBeApproved = agm.trigger_set?.filter(
      ({ processed }) => !processed
    );

    const preSelectedTrigger = approved || toBeApproved?.[0] || rejected?.[0];

    if (!inspectionContext.state.selected_trigger_passage) {
      inspectionContext.dispatch({ type: 'SET_SELECTED_TRIGGER_PASSAGE', data: preSelectedTrigger })
    }
    const thisTrigger = { ...agm.trigger_set?.find(
      ({ id }) => id === trigger?.id
    )};

    inspectionContext.dispatch({ type: 'SET_SELECTED_TRIGGER_PASSAGE', data: thisTrigger });
  }, [JSON.stringify(agm.trigger_set)]);

  useEffect(() => {
    inspectionContext.dispatch({ type: 'SET_SELECTED_POINT_TRIGGERS', data: inspectionContext.run?.trackingpoint_set?.[agm.index] });
    
  }, [inspectionContext.run?.trackingpoint_set?.[agm.index]?.trigger_set]);

  /**
   *
   * @param trigger
   */
  const handleTriggerClick = (trigger: TriggerType) => {
    inspectionContext.dispatch({ type: 'SET_SELECTED_TRIGGER_PASSAGE', data: trigger })
  };

  if (!auth) return null;

  const Header = () => (
    <QubeTriggersOverlayPresetContainer>
      <div>
        <AGMTitle>Qube trigger</AGMTitle>
        <DeviceSerialNumber>
          {formatTimezoneDate({
            date: trigger?.best_estimate,
            timezone: settingsContext.state.timezone!.id,
            format: 'MM/DD HH:mm:ss',
          })}
        </DeviceSerialNumber>
      </div>

      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Typograph 
          type="caption"
          text={`${agm.trigger_set?.length || 0} triggers total`}
        />
      </div>
    </QubeTriggersOverlayPresetContainer>
  );

  return (
    <Overlay
      id="GT_DRAGGABLE_QUBE_TRIGGERS_OVEERLAY_WRAPPER"
      onClose={() => {
        inspectionContext.dispatch({ type: 'SET_SELECTED_TRIGGER_PASSAGE', data: undefined });
        inspectionContext.dispatch({ type: 'SET_SELECTED_POINT_TRIGGERS', data: undefined });
      }}
      Header={Header}
      x={inspectionContext.state.selected_point ? -372 : -10}
      y={100}
    >
      <QubeTriggersOverlayContentWrapper>
        <QubeTriggersOverlaySidebar style={{ maxHeight: `${window.innerHeight - 170}px` }}>
          <SatellitePassages
            agm={agm}
            onTriggerClick={handleTriggerClick}
            authToken={auth.token}
            isObserver={inspectionContext.state.permission_type === 'observer'}
            selected={trigger}
          />
        </QubeTriggersOverlaySidebar>
        
        <QubeTriggersOverlayContent>
          <Typograph 
            type="headline6"
            text={formatTimezoneDate({
              date: getTriggerTstamp(trigger),
              timezone: settingsContext.state.timezone?.id || 'UTC',
              format: 'H:mm:ss A',
            })}
          />
          
          <Charts trigger={trigger} />

          <DownloadWrapper>
            <MenuButton
              $hide={false}
              onClick={() => setShowMenu(!showMenu)}
            >
              Download
              <MenuListWrapper hide={!showMenu}>
                <MenuListItem
                  onClick={() => {
                    window.open(getMediaUrl(trigger, 'acx'), '_blank');
                    setShowMenu(!showMenu);
                  }}
                >
                  acx
                </MenuListItem>
                <MenuListItem
                  onClick={() => {
                    window.open(getMediaUrl(trigger, 'acy'), '_blank');
                    setShowMenu(!showMenu);
                  }}
                >
                  acy
                </MenuListItem>
                <MenuListItem
                  onClick={() => {
                    window.open(getMediaUrl(trigger, 'dcx'), '_blank');
                    setShowMenu(!showMenu);
                  }}
                >
                  dcx
                </MenuListItem>
                <MenuListItem
                  onClick={() => {
                    window.open(getMediaUrl(trigger, 'dcy'), '_blank');
                    setShowMenu(!showMenu);
                  }}
                >
                  dcy
                </MenuListItem>
                <MenuListItem
                  onClick={() => {
                    window.open(getMediaUrl(trigger, 'gphone'), '_blank');
                    setShowMenu(!showMenu);
                  }}
                >
                  gphone
                </MenuListItem>
                <MenuListItem
                  onClick={() => {
                    window.open(getMediaUrl(trigger, 'metadata'), '_blank');
                    setShowMenu(!showMenu);
                  }}
                >
                  metadata
                </MenuListItem>
              </MenuListWrapper>
            </MenuButton>
          </DownloadWrapper>
        </QubeTriggersOverlayContent>
      </QubeTriggersOverlayContentWrapper>
    </Overlay>
  );
};

export default QubeTriggersOverlay;
