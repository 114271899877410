import styled from 'styled-components';

export const QubeTriggersOverlayContainer = styled.div<{ maxHeight: number; offset: boolean }>`
  position: absolute;
  z-index: 11;
  
  top: 102px;
  right: ${({ offset }) => (offset ? '372px' : '10px')};

  display: none;
  width: auto;
  min-height: 200px;
  max-height: ${({ maxHeight }) => maxHeight}px;

  background-color: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;

  overflow: auto;

  @media (min-width: 1024px) {
    display: grid;
    grid-template-rows: auto 1fr;
  }
`;

export const QubeTriggersOverlayPresetContainer = styled.div`
  position: sticky;
  top: 0;

  width: 100%;
  
  display: flex;
  align-items: center;
  justify-content: space-between;

  cursor: move;

  padding-right: 8px;
  background-color: ${({ theme }) => theme.colors.surface_white};
  z-index: 2;
`;

export const QubeTriggersOverlayContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 320px 1fr;
  grid-template-rows: 1fr;
`;

export const QubeTriggersOverlaySidebar = styled.aside`
  padding: 16px;
  padding-bottom: 32px;
  height: 100%;
  z-index: 1;
  overflow: auto;
  background-color: #ffffff;
  
  > h4 {
    padding-right: 16px;
    padding-left: 16px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 1em;

    margin-bottom: 8px;
  }
`;

export const QubeTriggersOverlaySidebarList = styled.ul``;

export const QubeTriggersOverlaySidebarListItem = styled.li<{ isSelected: boolean }>`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 1em;

  cursor: pointer;

  padding-right: 16px;
  padding-left: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  transition-duration: 0.4s;
  background-color: ${({ isSelected }) => (isSelected ? '#ffffff' : 'transparent')};
  
  &:hover {
    background-color: #ffffff;
  }
`;

export const QubeTriggersOverlayContent = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;
  align-items: start;
  gap: 16px;
  padding: 16px 32px;


  > .VictoryContainer {
    height: 200px !important;
    background-color: ${({ theme }) => theme.colors.neutra_50};
    z-index: 0;
  }

  @keyframes blinker {
    0% {
      opacity: 1;
    }
    25% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`

export const AGMTitle = styled.h2`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;

  color: #161e2e;

  margin-bottom: 4px;
`;

export const DeviceSerialNumber = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;

  color: #161e2e;
`;


export const QubeTriggersOverlayContentTstamp = styled.h3``;

export const DownloadWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const MenuButton = styled.button<{ $hide: boolean }>`
  padding: 5px 10px;
  border: 1px solid #e5e5e5;
  border-radius: 6px;
  background-color: #5850ec;
  color: white;

  cursor: pointer;
  position: relative;
  font-weight: 500;

  ${({ $hide }) => ($hide ? 'display: none;' : '')}
`;

export const MenuListWrapper = styled.div<{ hide: boolean }>`
  position: absolute;
  bottom: 40px;
  right: 0;

  display: grid;


  background: #ffffff;
  box-shadow: 0px 15px 30px rgba(51, 57, 78, 0.15);
  border-radius: 6px;

  overflow: hidden;
  z-index: 3;
  ${({ hide }) => (hide ? 'display: none;' : '')}
`;

export const MenuListItem = styled.div`
  padding: 8px;

  display: grid;
  grid-template-columns: auto;
  justify-items: start;

  gap: 6px;

  font-size: 13px;
  font-weight: 500;

  color: #6b7280;

  :hover {
    background-color: #f1f1f1;
  }
`;

