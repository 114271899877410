import { TriggerType } from 'app/modules/detection/detection.interfaces';
import { InfoData, ResetZoom, Wrapper, ZoomLabel } from './charts.style';
import MagneticChart from './components/magnetic-chart/magnetic-chart';
import { useContext, useEffect, useState } from 'react';
import SettingsStore from 'app/modules/settings/settings.context';
import moment from 'moment-timezone';

interface Props {
  trigger: TriggerType | undefined;
}

export interface MousePosition {
  x: Date;
}

export interface ZoomDomain {
  x: [Date, Date];
  y: [number, number];
}



/**
 * 
 * @returns 
 */
const Charts = (props: Props) => {
  const settingsContext = useContext(SettingsStore);
  const [zoomDomain, setZoomDomain] = useState<ZoomDomain>();
  const [mousePosition, setMousePosition] = useState<MousePosition>();
  const [rangeY, setRangeY] = useState<[number, number]>();
  const [rangeX, setRangeX] = useState<[Date, Date]>();
  const [zoomPercentage, setZoomPercentage] = useState<string>();
  const [lastTrigger, setLastTrigger] = useState<number>();
  const delta = rangeY ? (rangeY[1] - rangeY[0]) / 15 : 0;

  useEffect(() => {
    if (zoomDomain && rangeX) {
      const initDate = rangeX[0].getTime();
      const endDate = rangeX[1].getTime();
      const zoomInitDate = zoomDomain.x[0].getTime();
      const zoomEndDate = zoomDomain.x[1].getTime();
      
      const fullRangeLength = endDate - initDate;
      const zoomedRangeLength = zoomEndDate - zoomInitDate;

      const zoomPercentage = ((zoomedRangeLength / fullRangeLength) -1) * -100;
      setZoomPercentage(zoomPercentage.toFixed(2));
    }
    
  }, [zoomDomain, rangeX]);
  
  useEffect(() => {
      if (!lastTrigger || !props.trigger?.id) {
        setLastTrigger(props.trigger?.id || undefined);
      }
      if (props.trigger?.id !== lastTrigger) {
        setRangeX([new Date(0), new Date(100000)]);
        setRangeY([0, 0]);
        setMousePosition(undefined);
      };
      setLastTrigger(props.trigger?.id || undefined);
  }, [props.trigger]);

  const resetZoomChart = () => {
    if (rangeX && rangeY) {
      const timezone = settingsContext.state.timezone?.id;
  
      const initTime = rangeX[0];
      const endTime = rangeX[1];
      
      const tzInitDate = moment.tz(initTime, timezone || 'UTC');
      const tzEndDate = moment.tz(endTime, timezone || 'UTC');
  
      const initDate = tzInitDate.toDate();
      const endDate = tzEndDate.toDate();
  
      setZoomDomain({ x: [initDate, endDate], y: [rangeY[0], rangeY[1] + delta] });
    }
  };

  if (!props.trigger?.medias) {
    return (
      <Wrapper>
        <ZoomLabel>
          This passage information has no additional data available at this time.
        </ZoomLabel>
      </Wrapper>
    );
  }

  return (
    <Wrapper onMouseLeave={() => setMousePosition(undefined)}>
      <MagneticChart
        trigger={props.trigger}
        resetZoomChart={resetZoomChart}
        setZoomDomain={setZoomDomain}
        zoomDomain={zoomDomain}
        setMousePosition={setMousePosition}
        mousePosition={mousePosition}
        setRangeY={(y) => {
          if (!rangeY || y[0] === 0 || y[1] === 0) {
            return setRangeY(y);
          }
          const ltY = y[0] < rangeY[0] ? y[0] : rangeY[0];
          const gtY = y[1] > rangeY[1] ? y[1] : rangeY[1];
          setRangeY([ltY, gtY]);
        }}
        setRangeX={(x) => {
            return setRangeX(x);
        }}
        rangeY={rangeY}
        rangeX={rangeX}
        delta={delta}
        type="ac"
        title="Transmitter Data"
      />
      <MagneticChart
        trigger={props.trigger}
        resetZoomChart={resetZoomChart}
        setZoomDomain={setZoomDomain}
        zoomDomain={zoomDomain}
        setMousePosition={setMousePosition}
        mousePosition={mousePosition}
        setRangeY={(y) => {
          if (!rangeY || y[0] === 0 || y[1] === 0) {
            return setRangeY(y);
          }
          const ltY = y[0] < rangeY[0] ? y[0] : rangeY[0];
          const gtY = y[1] > rangeY[1] ? y[1] : rangeY[1];
          setRangeY([ltY, gtY]);
        }}
        setRangeX={(x) => {
            return setRangeX(x);
        }}
        rangeY={rangeY}
        rangeX={rangeX}
        delta={delta}
        type="dc"
        title="MAG"
      />
      <InfoData>
        <ZoomLabel>zoom: {zoomPercentage}%</ZoomLabel>
        <ResetZoom onClick={() => resetZoomChart()}>{`reset zoom`}</ResetZoom>
      </InfoData>
    </Wrapper>
  );
};

export default Charts;
