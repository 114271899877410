import { createGlobalStyle } from "styled-components";

export const TPointMarkerGlobalStyle = createGlobalStyle`
  .GT-TRACKING-POINT-MARKER-ICON {
    position: relative;
    
    >img {
      position: absolute;
      width: 40px;
      height: 40px;
      top: -20px;
      left: -20px;
      z-index: 0;
    }
    >.display-none {
      display: none;
    }
    >label {
      position: absolute;
      left: -14px;
      z-index: 1;
      background-color: #646464;
      color: #ffffff;
      padding: 4px 8px;
      top: 28px;

      display: inline-flex;
      max-width: 200px;
      white-space: nowrap;

      font-family: Inter;
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 1em;

      border-radius: 3px;
      box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;

      &:before {
        content: '';
        display: block;
        position: absolute;
        bottom: 100%;
        width: 0;
        height: 0;
        border: 6px solid transparent;
        border-bottom-color: #646464;
        z-index: 3;
      }
    }
    
  }
`;