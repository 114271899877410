import { useContext, useEffect, useState } from 'react';
import { centerMap, mapSurvey } from './survey.controller';
import { Props } from './survey.d';
import SurveyStore from 'app/modules/survey/survey.context';
import SPMarker from './components/sp-marker/sp-marker';
import Pipeline from './components/pipeline/pipeline';
import { SurveyContext } from 'app/modules/survey/survey.context.d';
import Driveline from './components/driveline/driveline';
import { PipelineType } from 'app/modules/survey/survey.interfaces';
import TPMarker from '../run/components/tp-marker/tp-marker';
import { TrackingPointCascadeType } from 'app/modules/inspection/inspection.interfaces';

/**
 * 
 * @param param0 
 * @returns 
 */
const Survey = ({
  map,
  zoom,
  // hasLabels,
  localSurvey,
}: Props) => {
  const surveyContext = useContext(SurveyStore);
  console.log('🚀 ~ surveyContext:', surveyContext);
  const [pipeline, setPipeline] = useState<PipelineType>()
  const [survey, setSurvey] = useState<SurveyContext['survey']>()
  const [pointsDic, setPointsDic] = useState<SurveyContext['points_dic']>({})

  // Center map
  useEffect(() => {
    if (pipeline) {
      centerMap({
        pipeline: pipeline as any,
        map,
      });
    }
  }, [pipeline]);

  // update pipeline from survey store
  useEffect(() => {
    if (surveyContext.state?.pipeline) {
      setPipeline(surveyContext.state?.pipeline);
    }
  }, [surveyContext.state?.pipeline]);
  
  // update survey points from survey store
  useEffect(() => {
    if (surveyContext.state?.points_dic) {
      setPointsDic(surveyContext.state?.points_dic);
    }
  }, [surveyContext.state?.points_dic]);
  
  // update survey from survey store
  useEffect(() => {
    if (surveyContext.state?.survey) {
      setSurvey(surveyContext.state?.survey);
    }
  }, [surveyContext.state?.survey]);

  // update everything from local survey
  useEffect(() => {
    if (localSurvey) {
      mapSurvey(
        localSurvey,
        setPipeline,
        setSurvey,
        setPointsDic,
      );
    }
  }, [localSurvey]);

  return (
    <>
      { map && zoom &&
        survey?.surveypoint_set?.map((tId: any, index) => {
          const point = pointsDic[tId]
          return (
            <SPMarker
              hasLabels={ false/* hasLabels */}
              zoom={zoom}
              key={`${tId}-${index}`}
              point={point}
              map={map}
            />
          );
        })
      }
      { map &&
        pipeline ? (
          <Pipeline
            pipeline={pipeline}
            map={map}
          />
        ) : null
      }
      { map &&
        pipeline?.driveline ? (
          <Driveline
            driveline={pipeline.driveline}
            map={map}
          />
        ) : null
      }
      { map &&
        survey?.surveypoint_set.map((p, i) =>(
          <TPMarker
            key={`survey-point${p.id}-${i}`}
            point={p as unknown as TrackingPointCascadeType}
            index={i}
            map={map}
            zoom={zoom}
            hasLabels={false}
            hasRuler={false}
            measureTool={undefined}
          />
        ))
      }

    </>
  );
};

export default Survey;
