import { deleteSurveyPoint, serializePoints, updateSurveyPoint, updateSurveyPointMedia } from './handlers/survey.sp.handlers';
import { serializeSurvey } from './handlers/survey.survey.handlers';
import {
  SurveyContext,
  SurveyDispatchType,
} from './survey.context.d';
import { SurveyMediaType, SurveyPointType, SurveyType } from './survey.interfaces.d';

/**
 * 
 * @param state
 * @param action 
 * @returns account context state
 */
export const reducer = (
  state: SurveyContext,
  action: SurveyDispatchType
): SurveyContext => {
  switch (action.type) {
    case 'SET_SURVEY':
      return serializeSurvey(state, action.data as SurveyType);
    case 'SET_LOADING':
      return { ...state, loading: action.data as SurveyContext['loading'] };
    case 'OPEN_MODAL':
      return { ...state, opened_modal: action.data as SurveyContext['opened_modal'] };
    case 'DELETE_POINT':
      return deleteSurveyPoint(state, action.data as SurveyPointType);
    case 'UPDATE_POINT':
      return updateSurveyPoint(state, action.data as SurveyPointType);
    case 'UPDATE_MEDIA':
      return updateSurveyPointMedia(state, action.data as SurveyMediaType);
    case 'SET_POINTS':
      return serializePoints(state);
    case 'SET_SELECTED_POINT':
      return { ...state, selected_point: action.data as SurveyContext['selected_point'] };
    case 'SET_PIPELINE':
      return { ...state, pipeline: action.data as SurveyContext['pipeline'] };

    default:
      throw new Error(`Unknown survey reducer action ${action.type}`);
  }
};
