import styled from "styled-components";

export const FloatingRefreshNotificationContainer = styled.div`
  position: fixed;

  top: 16px;
  right: 16px;
  
  padding: 8px 24px 8px 16px;
  background-color: #323030;
  
  
  display: grid;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  animation: slide-up 0.5s ease;

  @keyframes slide-up {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }

  > p {
    color: ${({ theme }) => theme.colors.surface_white};
  }

  > button {
   padding: 0;
   text-decoration: underline;
  }
`;

