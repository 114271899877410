import {
  formatDistance,
  formatMps,
  formatTimezoneDate,
} from '@/lib/formatter';
import { Chart, LegendOptions, registerables } from 'chart.js';
import { columnsModel } from './chart-modal.model';
import { SettingsContext, Unit } from 'app/modules/settings/settings.context.d';
import { InspectionProviderValue } from 'app/modules/inspection/inspection.context.d';
import { Column, TableProps } from 'app/components/table/table.interfaces';
import { TableContentInterface } from './chart-modal.d';
import { TrackingPointCascadeType } from 'app/modules/inspection/inspection.interfaces';
import { _DeepPartialObject } from 'chart.js/dist/types/utils';



export const getSpeedCanvas = (tableContent: TableContentInterface[], speedUnit?: Unit) => {
  const speeds = tableContent
    .map((p) => ({
      x: p.index,
      y: p.passage ? formatMps({
          distance: p.speed,
          unit: speedUnit,
          returnNumber: true,
        }) as number || undefined : undefined
    }));
  return speeds;
};

/**
 *
 * @param points
 * @param distanceUnit
 * @returns
 */
const getElevationsCanvas = (tableContent: TableContentInterface[], distanceUnit?: Unit) =>
  tableContent.map((point) =>
    ({
      x: point.index,
      y: formatDistance({
        distance: point.elevation,
        unit: distanceUnit?.id || '',
        returnNumber: true,
      }) as number
    }));

/**
 *
 * @param points
 * @returns
 */
const getInclinationsCanvas = (tableContent: TableContentInterface[]) =>
  tableContent.map((point) => ({
    x: point.index,
    y: point.inclination
  }));

/**
 *
 * @param distanceUnit
 * @param speedUnit
 * @param canvas
 * @param setCanvas
 * @param run
 * @param selectedChart
 * @param runElevations
 * @returns
 */
export const setCanvasPlot = (
  settingsState: SettingsContext,
  canvas: Chart | undefined,
  setCanvas: (chart?: Chart) => void,
  inspectionState: InspectionProviderValue,
  selectedChart: string,
  tableContent: TableContentInterface[]
) => {
  if (!document || !inspectionState.run?.trackingpoint_set) return;
  const element: any = document.getElementById('gt-chart');
  if (!element) return;
  const ctx = element?.getContext('2d');
  if (canvas?.destroy) {
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    canvas.destroy();
  }


  Chart.register(...registerables);

  const distancesLabels = inspectionState.run?.trackingpoint_set.map((point) => {
    return `${point?.name?.slice(0, 10)}`
  })

  const speeds = getSpeedCanvas(tableContent, settingsState.speedUnit);

  const elevations = getElevationsCanvas(tableContent, settingsState.distanceUnit);
  const inclinations = getInclinationsCanvas(tableContent);
  const datasets: any[] = [];

  if (selectedChart === 'speed') {
    datasets.push({
      label: `Speed (${settingsState.speedUnit?.label})`,
      backgroundColor: 'rgb(255, 99, 132)',
      borderColor: 'rgb(255, 99, 132)',
      pointRadius: 3,
      data: [...speeds],
      tension: 0.1,
      tick: {},
      fill: false,
    });
  }

  if (selectedChart === 'elevation') {
    datasets.push({
      label: `Elevations (${settingsState.distanceUnit?.label})`,
      backgroundColor: 'transparent',
      borderColor: 'rgb(0, 99, 132)',
      pointRadius: 0,
      data: [...elevations],
    });
  }

  if (selectedChart === 'inclination') {
    datasets.push({
      label: `Inclination (°)`,
      backgroundColor: 'transparent',
      borderColor: 'rgb(127, 255, 0)',
      pointRadius: 0,
      data: [...inclinations],
    });
  }

  const myChart = new Chart(ctx, {
    type: 'line',
    data: {
      datasets,
    },
    options: {
      scales: {
        y: {
          beginAtZero: selectedChart === 'speed',
        },
        x: {
          type: 'linear',
          ticks: {
            // Include labels for all points
            callback: function(value) {
              return distancesLabels[value] ? distancesLabels[value] : '';
            }
          }
        }
      },
      plugins: {
        legend: false as unknown as _DeepPartialObject<LegendOptions<"line">>,
        title: {
          display: false,
        }
      },
    },
  }) as Chart;

  setCanvas(myChart);
};

/**
 *
 * @param run
 * @param timezone
 * @param distanceUnit
 * @param speedUnit
 * @param setTableContent
 * @param elevations
 * @returns
 */
export const loadTableContent = (
  inspectionState: InspectionProviderValue,
  settingsState: SettingsContext,
  setTableContent: (data: TableContentInterface[]) => void,
  tableContent: TableContentInterface[],
) => {
  if (!inspectionState.run) return;
  const trackingpointSet = inspectionState.run.trackingpoint_set;

  const content = trackingpointSet?.map(
    (point: TrackingPointCascadeType, i: number) => {
      const passage = point?.passage;
      return ({
        id: point?.id,
        name: point?.name,
        index: i,
        formatedSpeed: passage ? formatMps({
          distance: point?.speed,
          unit: settingsState.speedUnit,
        }) : undefined,
        passage: formatTimezoneDate({
          date: passage?.tstamp || '',
          format: 'll H:mm',
          timezone: settingsState.timezone?.id || '',
        }),
        distance: formatDistance({
          distance: point?.distance,
          unit: settingsState.distanceUnit?.id || '',
        }),
        formatedInclination: `${point?.inclination}°`,
        formatedElevation: formatDistance({
          distance: point?.elevation || '',
          unit: settingsState.distanceUnit?.id || '',
        }),
        location: `${point?.geometry?.coordinates[0].toFixed(
          6
        )}, ${point?.geometry?.coordinates[1].toFixed(6)}`,
        speed: passage ? point?.speed : undefined,
        elevation: point?.elevation,
        inclination: point?.inclination,
        
    }) 
  }) as TableContentInterface[];
  if (JSON.stringify(tableContent) !== JSON.stringify(content)){
    setTableContent(content);
  }
};

/**
 *
 * @param columnsKey
 * @param speedUnit
 * @param distanceUnit
 * @param timezone
 * @param elevations
 * @returns
 */
export const getColumns = (
  columnsKey: string,
  settingsState: SettingsContext,
): TableProps['columns'] => {
  if (
    !settingsState.speedUnit ||
    !settingsState.distanceUnit ||
    !settingsState.timezone
  ) return [];

  const storagedColumns = window.localStorage.getItem(columnsKey);
  const parsedStorageColumns = storagedColumns
    ? JSON.parse(storagedColumns)
    : null;

  const allColumns: Column[] = columnsModel();

  if (parsedStorageColumns) {
    return parsedStorageColumns.map((sc: Column) => ({
      ...allColumns.find((c: Column) => sc.field === c.field),
      ...sc,
    }));
  }

  return allColumns;
};

/**
 *
 * @param run
 * @param estimation
 * @param speedUnit
 * @returns
 */
export const getSpeedValue = (
  inspectionState: InspectionProviderValue,
  speedUnit: Unit | undefined,
): string | number => {
  const points = inspectionState.run?.trackingpoint_set || []

  let currentSpeed = 0;

  if (!inspectionState.run?.launched) {
    return '-';
  }

  if (!inspectionState.run?.is_finished && inspectionState.run?.estimation) {
    currentSpeed = inspectionState.run?.estimation.speed || 0;
  }

  const lastPoint = points[points.length - 1];
  if (inspectionState.run?.is_finished && lastPoint) {
    currentSpeed = lastPoint.speed;
  }

  return formatMps({
    distance: currentSpeed,
    unit: speedUnit,
  });
};

/**
 *
 * @param run
 * @param estimation
 * @param speedUnit
 * @returns
 */
export const getInclinationValue = (
  inspectionState: InspectionProviderValue,
) => {
  const points = inspectionState.run?.trackingpoint_set || []

  let inclination: number | string = 0;

  if (!inspectionState.run?.launched) {
    return '-';
  }

  // TODO: Why estimation returns a number sometimes, maybe the id not serialized?
  if (
    inspectionState.run?.launched &&
    !inspectionState.run?.is_finished &&
    typeof inspectionState.run?.estimation === 'object' &&
    inspectionState.run?.estimation?.inclination
  ) {
    inclination = inspectionState.run?.estimation.inclination;
    inclination = parseFloat(`${inspectionState.run?.estimation.inclination}`);
    return `${inclination.toFixed(1)}°`;
  }

  const lastPoint = points[points.length - 1];
  if (inspectionState.run?.is_finished && lastPoint) {
    inclination = parseInt(lastPoint.speed)

    return inclination ? `${inclination.toFixed(1)}°` : '-';
  }

  return '-';
};

/**
 *
 * @param run
 * @param estimation
 * @param speedUnit
 * @returns
 */
export const getElevationValue = (
  inspectionState: InspectionProviderValue,
  distanceUnit: Unit | undefined
) => {
  const points = inspectionState.run?.trackingpoint_set || []

  if (!inspectionState.run?.launched) {
    return '-';
  }

  let elevation = 0;

  if (
    inspectionState.run?.launched &&
    !inspectionState.run?.is_finished &&
    inspectionState.run?.estimation
  ) {
    elevation = inspectionState.run?.estimation.elevation || 0;
  }

  const lastPoint = points[points.length - 1];
  if (inspectionState.run?.is_finished && lastPoint) {
    elevation = parseInt(lastPoint.elevation)
  }

  return formatDistance({
    distance: elevation,
    unit: distanceUnit?.id,
  });
};
