import { Chart, Point as ChartPoint } from 'chart.js';
import { useContext, useEffect, useState } from 'react';
import {
  getColumns,
  getElevationValue,
  getInclinationValue,
  getSpeedCanvas,
  getSpeedValue,
  loadTableContent,
  setCanvasPlot,
} from './chart-modal.controller';
import { TableContentInterface } from './chart-modal.d';
import {
  ModalWrapper,
  Modal,
  Header,
  Title,
  CloseIcon,
  PlotWrapper,
  Widget,
  Value,
  Label,
  TableWrapper,
  ChartCanvas,
  CanvasContainer,
} from './chart-modal.style';
import InspectionStore from 'app/modules/inspection/inspection.context';
import SettingsStore from 'app/modules/settings/settings.context';
import Table from 'app/components/table/table';

const columnsKey = 'GT@speed-modal/columns';

/**
 *
 * @returns
 */
const ChartModal = () => {
  const inspectionContext = useContext(InspectionStore);
  const settingsContext = useContext(SettingsStore);

  // chart
  const [canvas, setCanvas] = useState<Chart>();
  const [selectedChart, setSelectedChart] = useState<string>('speed');
  // table
  const [tableContent, setTableContent] = useState<TableContentInterface[]>([]);
  const [selectedPoint, setSelectedPoint] = useState<any>(null);
  const [show, setShow] = useState<string>();

  useEffect(() => {
    if (
      !inspectionContext.run ||
      !settingsContext.state.distanceUnit ||
      !settingsContext.state.speedUnit
    ) return;
    
    
    loadTableContent(
      inspectionContext,
      settingsContext.state,
      setTableContent,
      tableContent
    );
  }, [JSON.stringify(inspectionContext.run?.trackingpoint_set)]);

  useEffect(() => {
    if (
      !inspectionContext.run ||
      !settingsContext.state.distanceUnit ||
      !settingsContext.state.speedUnit ||
      !tableContent?.length
    ) return;
    
    setCanvasPlot(
      settingsContext.state,
      canvas,
      setCanvas,
      inspectionContext,
      selectedChart,
      tableContent
    );

  }, [show, selectedChart]);

  useEffect(() => {
    if (
      !canvas ||
      !tableContent?.length
    ) return;
    
    if (selectedChart === 'speed') {
      const nSpeeds = getSpeedCanvas(tableContent, settingsContext.state.speedUnit);
      canvas.data.datasets[0].data = nSpeeds as ChartPoint[];
      canvas.update();
    }

  }, [tableContent]);

  useEffect(() => {
    const keys = Object.keys(inspectionContext.state.modals || {});
    const active = keys.find((key) => {
      if (key === 'chart' && inspectionContext.state.modals[key]) {
        setShow(key);
        return true;
      }
      const pieces = key.split('/');
      if (pieces[0] === 'chart' && inspectionContext.state.modals[key]) {
        setShow(key);
        setSelectedChart(pieces[1])
        return true;
      }
    });
    if (!active) setShow(undefined);
  
  }, [inspectionContext.state.modals]);

  if (!inspectionContext.run || !show) return null;

  return (
    <ModalWrapper>
      <Modal>
        <Header>
          <Title>Chart Board</Title>
          <CloseIcon onClick={() => inspectionContext.dispatch({ type: 'TOGGLE_MODAL', data: show})}>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="14.2427"
                y="4.34375"
                width="2"
                height="14"
                rx="1"
                transform="rotate(45 14.2427 4.34375)"
                fill="#000"
              />
              <rect
                x="15.6567"
                y="14.2422"
                width="2"
                height="14"
                rx="1"
                transform="rotate(135 15.6567 14.2422)"
                fill="#000"
              />
            </svg>
          </CloseIcon>
        </Header>

        <PlotWrapper>
          <Widget onClick={() => setSelectedChart('speed')}>
            <Value>
              {
                getSpeedValue(
                  inspectionContext,
                  settingsContext.state.speedUnit
                ) || '-'
              }
            </Value>
            <Label>Speed</Label>
          </Widget>
          <Widget onClick={() => setSelectedChart('elevation')}>
            <Value>
              {
                getElevationValue(
                  inspectionContext,
                  settingsContext.state.distanceUnit
                ) || '-'
              }
            </Value>
            <Label>Elevation</Label>
          </Widget>
          <Widget onClick={() => setSelectedChart('inclination')}>
            <Value>
              {
                getInclinationValue(inspectionContext) || '-'
              }
            </Value>
            <Label>Inclination</Label>
          </Widget>
        </PlotWrapper>

        <CanvasContainer>
          <ChartCanvas id="gt-chart" data-testid={`chart-${selectedChart}`} />
        </CanvasContainer>

        <TableWrapper>
          <Table
            columns={getColumns(
              columnsKey,
              settingsContext.state,
            )}
            rows={tableContent}
            selectedLine={selectedPoint?.id}
            customStyle={'border: none;'}
            lineHandleClick={(data) => setSelectedPoint(data)}
            onResize={(columns) => {
              window.localStorage.setItem(columnsKey, JSON.stringify(columns));
            }}
          />
        </TableWrapper>
      </Modal>
    </ModalWrapper>
  );
};
ChartModal.defaultProps = {};

export default ChartModal;
