import { SurveyMediaType, SurveyPointType } from 'app/modules/survey/survey.interfaces';
import { SurveyContext } from '../survey.context.d';

/**
 * 
 * @param state 
 * @param point 
 * @returns 
 */
export const deleteSurveyPoint = (state: SurveyContext, point: { id: number }): SurveyContext => {
  if (!point.id) return state;
  const stateCopy = { ...state };
  const spDic = { ...state.points_dic };

  delete spDic[point.id];

  stateCopy.points_dic = spDic;

  return stateCopy;
}
/**
 * 
 * @param state 
 * @param point 
 * @returns 
 */
export const serializePoints = (state: SurveyContext): SurveyContext => {

  return state;
}

/**
 * 
 * @param state 
 * @param point 
 * @returns 
 */
export const updateSurveyPoint = (state: SurveyContext, point: SurveyPointType) => {
  const stateCopy = { ...state };
  const pointsDic = { ...stateCopy.points_dic }
  const oldPoint = stateCopy.points_dic[point.id] || {};
  
  pointsDic[point.id] = { ...oldPoint, ...point };
  stateCopy.points_dic = pointsDic;

  return stateCopy;
}

/**
 * 
 * @param state 
 * @param media 
 * @returns 
 */
export const updateSurveyPointMedia = (state: SurveyContext, media: SurveyMediaType) => {
  const stateCopy = { ...state };
  const mediasDic = { ...state.medias_dic };

  mediasDic[media.id] = media;

  stateCopy.medias_dic = mediasDic;

  return stateCopy;
};
