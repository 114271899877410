import { useContext, useEffect, useState } from 'react';
import { VictoryAxis, VictoryChart, VictoryTheme, VictoryLine } from 'victory';
import { ThemeContext } from 'styled-components';
import moment from 'moment';
import { getXYData, getTriggerTstamp } from 'app/modules/inspection/run/devices/desktop/components/qube-triggers-overlay/qube-triggers-overlay.controller';
import SettingsStore from 'app/modules/settings/settings.context';
import { TriggerType } from 'app/modules/detection/detection.interfaces';
import { formatTimezoneDate } from '@/lib/formatter';
import light from 'app/themes/light';
import { ChartName, Wrapper } from './magnetic-chart.style';
import { CustomContainer } from 'app/components/chart-files/custom-container';
import { MousePosition, ZoomDomain } from '../../charts';
import { formatDatum, formatTime } from '../../chart.handlers';
import Skeleton from 'app/components/skeleton/skeleton';

interface Props {
  trigger: TriggerType | undefined;
  zoomDomain: ZoomDomain | undefined;
  rangeY: [number, number] | undefined;
  rangeX: [Date, Date] | undefined;
  delta: number
  mousePosition: MousePosition | undefined;
  setZoomDomain: (domain: ZoomDomain | undefined) => void;
  setMousePosition: (position: MousePosition | undefined) => void;
  setRangeY: (y: [number, number]) => void;
  setRangeX: (x: [Date, Date]) => void;
  resetZoomChart: () => void;
  type: 'ac' | 'dc';
  width: number;
  height: number;
  title: string;
}

export interface MagneticData {
  x: Date;
  y: number;
}

/**
 * 
 * @returns 
 */
const MagneticChart = (props: Props) => {
  const styleContext = useContext(ThemeContext) as typeof light;
  const settingsContext = useContext(SettingsStore);
  const [magneticData, setMagnetic] = useState<{ x: MagneticData[], y: MagneticData[]}>();
  const [line, setLine] = useState<{x: Date, y: number}[]>();
  const [rangeY, setRangeY] = useState<[number, number]>();
  const [loading, setLoading] = useState<boolean>();
  const [mouseLabel, setMouseLabel] = useState<string[]>();

  useEffect(() => {
    props.resetZoomChart();
    props.setRangeY([0,0]);
    props.setRangeX([new Date(0),new Date(100000)]);
    return () => {
      props.resetZoomChart();
      props.setRangeY([0,0]);
      props.setRangeX([new Date(0),new Date(100000)]);
    }
  }, []);

  useEffect(() => {
    if (props.trigger) {
      const timezone = settingsContext.state.timezone?.id;
      getXYData(
        props.trigger,
        timezone,
        setMagnetic,
        setRangeY,
        rangeY,
        props.setRangeX,
        setLoading,
        props.type,
      );
    }
  }, [props.trigger?.medias, props.type]);

  useEffect(() => {
    if (rangeY) {
      props.setRangeY(rangeY);
    }
  }, [rangeY]);

  useEffect(() => {
    if (props.mousePosition?.x){
      const mouseLabel = ['',formatTime(settingsContext.state.timezone!, props.mousePosition?.x)];
      setMouseLabel(mouseLabel);
    }
  }, [props.mousePosition?.x]);

  useEffect(() => {
    if (magneticData) {
      props.resetZoomChart();
      const timezone = settingsContext.state.timezone?.id;
      const date = moment.tz(getTriggerTstamp(props.trigger), timezone || 'UTC')
      const firstAcxDate = magneticData.x[0]
      const lastAcxDate = magneticData.x[magneticData.x.length - 1]

      if (!firstAcxDate || !lastAcxDate) {
        setLine([]);
        return;
      }
  
      const xTriggerDate = date.toDate();
      if (rangeY) {
  
        const line = [
          { x: xTriggerDate, y: rangeY[0] },
          { x: xTriggerDate, y: rangeY[1] + props.delta },
        ];
    
        setLine(line);
      }
    }
  }, [magneticData, rangeY, props.rangeX]);

  return (
    <Skeleton loading={!!loading} width={`${props.width}px`} height={`${props.height}px`}>
      <Wrapper>
      <ChartName>{props.title}</ChartName>
        <VictoryChart
          width={props.width}
          height={props.height}
          scale={{ x: "time" }}
          theme={VictoryTheme.material}
          containerComponent={
            <CustomContainer
              cursorLabel={({ datum }) => formatDatum({ timezone: settingsContext.state.timezone, datum })}
              zoomDimension="x"
              zoomDomain={{
                ...props.zoomDomain,
                y: rangeY,
              }}
              onMouseMove={(data)=> props.setMousePosition(data)}
              allowPan={true}
              onZoomDomainChange={(domain) => {
                props.setZoomDomain(domain as unknown as { x: [Date, Date], y: [number, number] });
              }}
            />
          }
        >
          <VictoryAxis
            axisValue={0}
            tickFormat={() => ''}
            style={{
              axis: {
                stroke: styleContext.colors.neutra_400,
              },
            }}
          />
          <VictoryAxis
            axisValue={rangeY ? rangeY[0] : 0}
          />
          <VictoryAxis
            width={500}
            height={400}
            domain={{
              ...props.zoomDomain,
              y: rangeY,
            }}
            dependentAxis
            tickCount={5}
            crossAxis={false}
          />
          <VictoryLine
            style={{
              data: { stroke: styleContext.colors.system_danger }
            }}
            data={magneticData?.x}
          />
          <VictoryLine
            style={{
              data: { stroke: styleContext.colors.primary_500 }
            }}
            data={magneticData?.y}
          />
          {props.trigger ? (
            <VictoryLine
              style={{
                data: {
                  stroke: styleContext.colors.neutra_400,
                  strokeWidth: 1,
                },
              }}
              data={line}
              key={`trigger-${props.trigger.id}`}
              labels={({ datum }) => (!rangeY || (datum.y > rangeY[0]) ? formatTimezoneDate({
                date: getTriggerTstamp(props.trigger),
                timezone: settingsContext.state.timezone?.id || '',
                format: 'HH:mm:ss.SSS',
              }) : '')}
            />
          ) : null}
          {props.mousePosition && rangeY !== undefined ? (
            <VictoryLine
              key={`${props.title}-mouse-line`}
              style={{
                data: {
                  stroke: '#000',
                  strokeWidth: 1,
                },
                labels: {
                  fill: '#000',
                  padding: 15,
                  pointerEvents: 'none',
                  textAnchor: 'start',
                  backgroundColor: '#FFF',
                  stroke: '#FFF',
                }
              }}
              data={[
                { x: props.mousePosition.x, y: rangeY[0] },
                { x: props.mousePosition.x, y: rangeY[1] + props.delta },
              ]}
              labels={mouseLabel}
            />
          ) : null}
        </VictoryChart>
      </Wrapper>
    </Skeleton>
  );
};

MagneticChart.defaultProps = {
  width: 500,
  height: 230,
}

export default MagneticChart;
