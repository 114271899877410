import styled from 'styled-components';

export const ChartName = styled.label`
  font-size: 0.9rem;
  margin-bottom: 1rem;
`;

export const Wrapper = styled.div`
  font-size: 0.9rem;
  margin-bottom: 1rem;
`;
