import styled from 'styled-components';

export const ChartName = styled.label`
  font-size: 0.9rem;
  margin-bottom: 1rem;
`;

export const Wrapper = styled.div`
  font-size: 0.9rem;
  margin-bottom: 1rem;
`;
export const InfoData = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ZoomLabel = styled.label`
  display: flex;
  justify-content: space-between;
  font-size: 0.7rem;
`;
export const ResetZoom = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-size: 0.7rem;

  height: 20px;
  padding-left: 4px;
  padding-right: 4px;
  background-color: #fff;

  border: 1px solid ${({ theme }) => theme.colors.neutra_300};
  border-radius: 6px;

  cursor: pointer;

  transition-duration: 400ms;
`;
