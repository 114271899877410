import Typograph from 'stories/type/typograph/typograph';
import { FloatingRefreshNotificationContainer } from './floating-refresh-notification.style';
import TextButton from 'stories/base/buttons/text-button/text-button';
import { useStatus } from 'app/services/no-connection.service';
import { useEffect, useState } from 'react';

interface Props {
  noConnectionSince: Date | undefined;
}

/**
 * 
 * @param props 
 * @returns 
 */
const FloatingRefreshNotification = (props: Props) => {
  const { status } = useStatus(props.noConnectionSince);
  const [socketOffline, setSocketOffline] = useState<boolean>(false);
  const [serverOffline, setServerOffline] = useState<boolean>(false);
  const [updateAvailable, setUpdateAvailable] = useState<boolean>(false);

  const handleRefresh = () => {
    setUpdateAvailable(false);
    window.location.reload();
  };

  useEffect(() => {    
    if (!status) return;
    setUpdateAvailable(!socketOffline && !!status?.online && serverOffline);
    setServerOffline(!status?.online);
  }, [socketOffline]);

  useEffect(() => {
    setSocketOffline(!!props.noConnectionSince);
  }, [props.noConnectionSince]);

  // if there is no connection since, do not show the notification
  if (!updateAvailable) return null;

  return (
    <FloatingRefreshNotificationContainer>
      <Typograph type="body2">An update is available. Would you like to refresh?</Typograph>
      <TextButton styleType="negative" onClick={handleRefresh} text="Refresh" />
    </FloatingRefreshNotificationContainer>
  );
};

export default FloatingRefreshNotification;
