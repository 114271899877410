import axios from 'axios';
import { useEffect, useState } from 'react';

/**
 * check connection status
 */
const getStatus = () => 
  axios.get(`/status.json`);

export type StatusType = {
  online: boolean;
};

export const useStatus = (noConnectionSince: Date | undefined) => {
  const [status, seStatus] = useState<StatusType | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | undefined>(undefined);

  useEffect(() => {
    getStatus()
      .then((response) => {
        seStatus(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error.message);
        setLoading(false);
      });
  }, [noConnectionSince]);

  return { status, loading, error };
};