import { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import ColumnSelector from './components/column-selector/columnSelector';
import Header from './components/header/header';
import MediaModal from './components/media-modal/media-modal';
import ReportImage from './components/report-image/report-image';
import {
  ReportContainer,
  PDFWrapper,
  Page,
  InitialPage,
  RunInformationWrapper,
  Title,
  RunName,
  MapWrapper,
  InfoWrapper,
  ValuesInfoWrapper,
  Label,
  Value,
  GTAttributeInformation,
  CompanyName,
  GtLogo,
  TrackingSheetPage,
  TrackingSheetHeader,
  DarkTitle,
  PageName,
  FloatGTAttributeInformation,
  DarkCompanyName,
  TrackingPointPage,
  TrackingPointName,
  TrackingPointInformations,
  TrackingPointInformationSection,
  TrackingPointInformationSectionColumn,
  TrackingPointInformationSectionName,
  TrackingPointInformationSectionList,
  TrackingPointImages,
  TrackingPointInformationSectionListItem,
  TrackingPointInformationSectionListLabel,
  TrackingPointInformationSectionListValue,
  Wrapper,
  ReportImageTitle,
  InclinationCanvas,
  SpeedCanvas,
  ElevationCanvas,
  MapContainer,
} from './report.style';
import {
  formatDistance,
  formatMps,
  formatTimezoneDate,
} from '@/lib/formatter';
import { trackingSheetColumns } from './report.model';
import {
  CanvasObj,
  getSpeedCanvas,
  setCanvasPlot,
} from './report.controller';
import SettingsStore from 'app/modules/settings/settings.context';
import InspectionStore from '../inspection.context';
import { RunCascadeType, TrackingPointCascadeType } from '../inspection.interfaces';
import Table from 'app/components/table/table';
import { Column } from 'app/components/table/table.interfaces';
import Skeleton from 'app/components/skeleton/skeleton';
import Map from 'app/components/common/map/map';

const columnsKey = 'GT@report/columns';

/**
 *
 * @returns
 */
const renderTrackingSheetPage = (
  trackingpoints: any,
  timezone: any,
  speedUnit: any,
  run: RunCascadeType | undefined,
  count: number,
  columns: any,
  allColumns: any,
  setColumns: (data: any) => void,
) => {
  if (!run) return;
  const points = trackingpoints.slice(count * 22, count * 22 + 22);

  return (
    <TrackingSheetPage>
      <FloatGTAttributeInformation>
        Report prepared by CDI (
        <a href="https://globaltrack.info">globaltrack.info</a>)
      </FloatGTAttributeInformation>
      <DarkCompanyName>
        Report Prepared for — {run.survey?.name || ''}
      </DarkCompanyName>
      <TrackingSheetHeader>
        <DarkTitle>
          <GtLogo
            orientation={
              run?.project?.customer?.subscribed &&
                run?.project?.customer?.company?.logo
                ? run?.project?.customer?.company?.logo
                : 'PORTRAIT'
            }
            src={
              run?.project?.customer?.company?.logo
                ? run.project.customer.company.logo
                : '/assets/icons/gt-logo.svg'
            }
          />
          {run?.project?.customer?.subscribed
            ? run?.project?.customer.name
            : 'GlobalTrack'}
        </DarkTitle>
      </TrackingSheetHeader>
      <PageName>
        Tracking Sheet <small>({count + 1})</small>
      </PageName>
      <ColumnSelector
        customStyle={`
          @media print {
            display: none;
          }
        `}
        columns={allColumns}
        selecteds={columns}
        onSelect={(c: any) => {
          const isSelected = columns.find((cl: any) => cl.field === c.field);
          if (isSelected) {
            const newColumns = columns.filter((cl: any) => cl.field !== c.field);
            setColumns(newColumns);
            window.localStorage.setItem(columnsKey, JSON.stringify(newColumns));
          } else {
            const newColumns = [...columns, c];
            setColumns(newColumns);
            window.localStorage.setItem(columnsKey, JSON.stringify(newColumns));
          }
        }}
      />
      <Table
        columns={columns}
        rows={points}
        data={{
          timezone,
          speedUnit,
        }}
        onResize={(columns) => {
          window.localStorage.setItem(columnsKey, JSON.stringify(columns));
        }}
      />
    </TrackingSheetPage>
  );
};

const renderTrackingPointListItems = (
  items: { label: string; value: string | undefined | null }[]
) =>
  items.map(({ label, value }: { label: string; value: string | undefined | null }) =>
    value ? (
      <TrackingPointInformationSectionListItem key={label}>
        <TrackingPointInformationSectionListLabel>
          {label}
        </TrackingPointInformationSectionListLabel>
        <TrackingPointInformationSectionListValue>
          {value}
        </TrackingPointInformationSectionListValue>
      </TrackingPointInformationSectionListItem>
    ) : null
  );

/**
 *
 * @returns
 */
const renderTrackingPointPage = ({ run, point, timezone, speedUnit, distanceUnit, setMedia }) => {
  if (!run?.trackingpoint_set || !point) return;

  const next = run.trackingpoint_set[point.index + 1];
  const prev = run.trackingpoint_set[point.index - 1];
  const passage = point.passage;

  const agmItems = [
    { label: 'AGM Station #', value: point.index },
    {
      label: 'AGM Location Description',
      value: point.location_description,
    },
    { label: 'Milepost', value: point.milepost },
    {
      label: 'State / County',
      value: `${point.state || '-'} / ${point.county || '-'}`,
    },
    {
      label: 'Passage Date',
      value: passage?.tstamp
        ? formatTimezoneDate({
          date: passage.tstamp,
          timezone: timezone?.id,
          format: 'MMM DD, YYYY',
        })
        : null,
    },
    {
      label: 'Passage Time',
      value: passage?.tstamp
        ? formatTimezoneDate({
          date: passage.tstamp,
          timezone: timezone?.id,
          format: 'HH:mm:ss.SSS',
        })
        : null,
    },
    {
      label: 'Passage Speed',
      value: point.speed
        ? formatMps({
          distance: point?.speed,
          unit: speedUnit,
        })
        : null,
    },
    {
      label: 'Passage Speed Delta',
      value: point.speed_delta
        ? formatMps({
          distance: point?.speed_delta,
          unit: speedUnit,
        })
        : null,
    },
    { label: 'Alignment Sheet', value: point.alignment_sheet },
    { label: 'Tract #', value: point.tract },
    { label: 'Land Owner', value: point.land_owner },
    { label: 'Owner Phone', value: point.owner_phone },
  ];


  const gpsItems = [
    {
      label: 'Survey Method',
      value: point.survey_method,
    },
    { label: 'Datum', value: point.datum },
    {
      label: 'Date/Time',
      value: point.surveyed_time,
    },
    {
      label: 'Latitude',
      value: point.geometry?.coordinates[0].toFixed(6),
    },
    {
      label: 'Longitude',
      value: point.geometry?.coordinates[1].toFixed(6),
    },
    {
      label: 'Elevation',
      value: point.elevation
        ? formatDistance({
          distance: point.elevation,
          unit: distanceUnit.id,
        })
        : null,
    },
    {
      label: 'Survey Accuracy',
      value: point.survey_accuracy,
    },
    { label: 'Geoid Model', value: point.geoid_model },
    {
      label: 'Antenna Height',
      value: point.antenna_height
        ? formatDistance({
          distance: point.antenna_height,
          unit: distanceUnit?.id,
        })
        : null,
    },
    {
      label: 'Elevation (TOP)',
      value: point.elevation_top,
    },
    {
      label: 'Ht. Correction (TOP)',
      value: point.ht_correction_top,
    },
    {
      label: 'Height Comment',
      value: point.height_comment,
    },
    {
      label: 'Survey Notes',
      value: point.survey_notes,
    },
    {
      label: 'Survey Technician',
      value: point.survey_technician,
    },
    {
      label: 'Survey Date',
      value: point.survey_date,
    },
    {
      label: 'Ellipsoid Height',
      value: point.ellipsoid_height
        ? formatDistance({
          distance: point.ellipsoid_height,
          unit: distanceUnit?.id,
        })
        : null,
    },
    {
      label: 'Ellipsoid Height (COP)',
      value: point.ellipsoid_height_cop
        ? formatDistance({
          distance: point.ellipsoid_height_cop,
          unit: distanceUnit?.id,
        })
        : null,
    },
  ];

  const primaryInformationsItems = [
    { label: 'Reference Source', value: '' },
    { label: 'Reference Station #', value: '' },
    { label: 'Depth of Cover', value: point.depth_of_cover },
    {
      label: 'Reference Technician',
      value: point.reference_technician,
    },
    {
      label: 'Cell Coverage',
      value: point.cell_coverage,
    },
    {
      label: 'Site Access',
      value: point.site_access,
    },
    {
      label: 'Reference Date',
      value: point.reference_date,
    },
    {
      label: 'Reference Time',
      value: point.reference_time,
    },
    {
      label: 'Field Notes',
      value: point.field_notes,
    },
    {
      label: 'Distance of AGM',
      value: point.speed
        ? formatDistance({
          distance: point.distance,
          unit: distanceUnit?.id,
        })
        : null,
    },
    { label: 'U/S AGM', value: prev?.name },
    { label: 'D/S AGM', value: next?.name },
  ];

  const sketch = point.media_set?.find((media) => media.is_sketch);
  const photos = point.media_set?.filter((media) => !media.is_sketch).splice(0, 6) || [];
  const firstPhoto = photos[0];

  let media = '';
  if (firstPhoto && firstPhoto.media) {
    media =
      firstPhoto.media[0] === '/'
        ? `http://localhost:8000${firstPhoto.media}`
        : firstPhoto.media;
  }
  return (
    <TrackingPointPage>
      <FloatGTAttributeInformation white>
        Report prepared by CDI (
        <a href="https://globaltrack.info">globaltrack.info</a>)
      </FloatGTAttributeInformation>

      <DarkCompanyName>Report Prepared for — {run.survey?.owner}</DarkCompanyName>
      <Wrapper>
        <TrackingSheetHeader>
          <DarkTitle>
            <GtLogo
              orientation={
                run?.project?.customer?.subscribed &&
                  run?.project?.customer?.company?.logo
                  ? run.project.customer.company.logo
                  : 'PORTRAIT'
              }
              src={
                run?.project?.customer?.subscribed &&
                run?.project?.customer?.company?.logo
                  ? run?.project.customer.company.logo
                  : '/assets/icons/gt-logo.svg'
              }
            />
            {run?.project?.customer?.subscribed
              ? run.project.customer.name
              : 'GlobalTrack'}
          </DarkTitle>
        </TrackingSheetHeader>

        <TrackingPointName>{point.name}</TrackingPointName>
        <TrackingPointInformations>
          <TrackingPointInformationSectionColumn>
            <TrackingPointInformationSection>
              <TrackingPointInformationSectionName>
                AGM Information
              </TrackingPointInformationSectionName>
              <TrackingPointInformationSectionList>
                {renderTrackingPointListItems(agmItems)}
              </TrackingPointInformationSectionList>
            </TrackingPointInformationSection>

            <TrackingPointInformationSection>
              <TrackingPointInformationSectionName>
                Reference Information
              </TrackingPointInformationSectionName>
              <TrackingPointInformationSectionList>
                {renderTrackingPointListItems(primaryInformationsItems)}
              </TrackingPointInformationSectionList>
            </TrackingPointInformationSection>
          </TrackingPointInformationSectionColumn>
          <TrackingPointInformationSection>
            <TrackingPointInformationSectionName>
              Survey Information
            </TrackingPointInformationSectionName>
            <TrackingPointInformationSectionList>
              {renderTrackingPointListItems(gpsItems)}
            </TrackingPointInformationSectionList>
          </TrackingPointInformationSection>
        </TrackingPointInformations>
      </Wrapper>
      <TrackingPointImages>
        <ReportImageTitle>Site sketch:</ReportImageTitle>
        <div>
          {sketch ? (
            <ReportImage
              sketch
              media={sketch.media}
              caption={sketch.caption || ''}
              onClick={() => setMedia(sketch.media)}
            />
          ) : (
            <p>No site sketch available.</p>
          )}
        </div>
        <ReportImageTitle>Site photo</ReportImageTitle>
        <div>
          {photos?.length ? (
            <ReportImage
              key={firstPhoto?.media}
              sketch
              media={media}
              onClick={() => setMedia(media)}
              caption={firstPhoto?.caption || ''}
            />
          ) : (
            <p>Medias not found.</p>
          )}
        </div>
      </TrackingPointImages>
    </TrackingPointPage>
  );
};

/**
 *
 * @returns
 */
const Report = () => {
  const history = useHistory();
  const settingsContext = useContext(SettingsStore);
  const inspectionContext = useContext(InspectionStore);
  const run = inspectionContext.run;
  const customer = run?.project?.customer;

  const [canvas, setCanvas] = useState<CanvasObj>();
  const [media, setMedia] = useState<string>();
  const [speeds, setSpeeds] = useState<(string | number)[]>();
  const [allColumns] = useState<any>(
    trackingSheetColumns({
      timezone: settingsContext.state.timezone,
      speedUnit: settingsContext.state.speedUnit,
      distanceUnit: settingsContext.state.distanceUnit,
    })
  );

  const [columns, setColumns] = useState<any>(allColumns);

  useEffect(() => {
    const storagedColumns = window.localStorage.getItem(columnsKey);
    const parsedStorageColumns = storagedColumns
      ? JSON.parse(storagedColumns)
      : null;

    if (parsedStorageColumns) {
      const cols = parsedStorageColumns.map(({ width, field }: Column) => ({
        ...columns.find((c: Column) => c.field === field),
        width,
      }));
      setColumns(cols.filter((c: Column) => c.field));
    }
  }, []);

  useEffect(() => {
    if (!run || !speeds) return;
    const charts = setCanvasPlot(
      settingsContext.state.distanceUnit,
      settingsContext.state.speedUnit,
      canvas,
      setCanvas,
      run,
      speeds,
    );

    return () => {
      charts?.elevationChart.destroy()
      charts?.inclinationChart.destroy()
      charts?.speedChart.destroy()
    }
  }, [JSON.stringify(speeds), settingsContext.state.distanceUnit, settingsContext.state.speedUnit]);

  useEffect(() => {
    if (!run) return;
    const speedArr = getSpeedCanvas(run, settingsContext.state.speedUnit);
    setSpeeds(speedArr as number[]);
  }, [run?.trackingpoint_set, settingsContext.state.distanceUnit, settingsContext.state.speedUnit]);

  const calculateSpeedAverage = (points: TrackingPointCascadeType[]) => {
    return points.reduce(
      (sum , point) => (point.speed ? sum + point.speed : sum),
      0
    ) / points.length;
  };

  const renderTrackingSheetPages = (
    columns: any,
    allColumns: any,
    setColumns: any,
  ) => {
    const pages: any[] = [];
    const length  = run?.trackingpoint_set?.length || 0;
    for (let count = 0; count < Math.ceil(length / 22); count += 1) {
      pages.push(
        <>
          <Page key={count}>
            {renderTrackingSheetPage(
              run?.trackingpoint_set,
              settingsContext.state.timezone,
              settingsContext.state.speedUnit,
              run,
              count,
              columns,
              allColumns,
              setColumns,
            )}
          </Page>
          <div className="break" />
        </>
      );
    }

    return pages;
  };

  const renderTrackingPointPages = (setMedia: any) => run?.trackingpoint_set?.map((trackingpoint) => (
      <>
        <Page key={trackingpoint.id}>
          {renderTrackingPointPage({ run, point: trackingpoint, timezone: settingsContext.state.timezone, speedUnit: settingsContext.state.speedUnit, distanceUnit: settingsContext.state.distanceUnit, setMedia })}
        </Page>
        <div className="break" />
      </>
    ));



  return (
    <ReportContainer>
      <Header
        name={run?.name || ''}
        onClose={() =>
          history.push(`/projects/${run?.project?.id || ''}`)
        }
      />
      <PDFWrapper id="pdf">
        <Page>
          <InitialPage>
            <RunInformationWrapper>
              <CompanyName>
                Report Prepared for —
                <Skeleton
                  width="100px"
                  height="20px"
                  loading={!run}
                  color="rgba(104, 98, 243, 0.2)"
                  inline
                >
                  {/* Todo: survey isn't loaded here. "survey.owner" */}
                  <p>{undefined || ''}</p>
                </Skeleton>
              </CompanyName>
              <InfoWrapper>
                <Title>
                  <GtLogo
                    orientation={customer?.company?.logo && customer?.subscribed ? 'LANDSCAPE' : 'PORTRAIT'}
                    src={customer?.company?.logo && customer?.subscribed ? customer?.company?.logo : '/assets/icons/gt-logo.svg'}
                  />
                  {customer?.subscribed ? customer.name : 'GlobalTrack'}
                </Title>
                <RunName>
                  <Skeleton
                    color="rgba(104, 98, 243, 0.2)"
                    width="200px"
                    height="38px"
                    loading={!run}
                  >
                    {run?.name}
                  </Skeleton>
                </RunName>
              </InfoWrapper>
              <ValuesInfoWrapper>
                <div>
                  <Label>Customer</Label>
                  <Skeleton
                    color="rgba(104, 98, 243, 0.2)"
                    width="200px"
                    height="18px"
                    loading={!run}
                  >
                    <Value>
                       {/* Todo: survey isn't loaded here. "survey.owner" */}
                      {undefined || customer?.name}
                    </Value>
                  </Skeleton>
                </div>
                <div>
                  <Label>Pipeline name</Label>
                  <Skeleton
                    color="rgba(104, 98, 243, 0.2)"
                    width="200px"
                    height="18px"
                    loading={!run}
                  >
                    <Value>{run?.pipeline?.name}</Value>
                  </Skeleton>
                </div>

                <div>
                  <Label>Launch Time</Label>
                  <Skeleton
                    color="rgba(104, 98, 243, 0.2)"
                    width="200px"
                    height="18px"
                    loading={!run}
                  >
                    <Value>
                      {run?.launch_time
                        ? formatTimezoneDate({
                          date: run?.launch_time,
                          timezone: settingsContext.state.timezone?.id || '',
                          format: 'MMM DD, YYYY HH:mm:ss',
                        })
                        : '-'}
                    </Value>
                  </Skeleton>
                </div>
                <div>
                  <Label>Finish Time</Label>
                  <Skeleton
                    color="rgba(104, 98, 243, 0.2)"
                    width="200px"
                    height="18px"
                    loading={!run}
                  >
                    <Value>
                      {run?.receive_time
                        ? formatTimezoneDate({
                          date: run?.receive_time || 0,
                          timezone: settingsContext.state.timezone?.id || '',
                          format: 'MMM DD, YYYY HH:mm:ss',
                        })
                        : '-'}
                    </Value>
                  </Skeleton>
                </div>
              </ValuesInfoWrapper>
              <ValuesInfoWrapper>
                <div>
                  <Label>Average Speed</Label>
                  <Skeleton
                    color="rgba(104, 98, 243, 0.2)"
                    width="200px"
                    height="18px"
                    loading={!run}
                  >
                    <Value>
                      {formatMps({
                        distance: calculateSpeedAverage(run?.trackingpoint_set || []),
                        unit: settingsContext.state.speedUnit,
                      })}
                    </Value>
                  </Skeleton>
                </div>
                <div>
                  <Label>Distance</Label>
                  <Skeleton
                    color="rgba(104, 98, 243, 0.2)"
                    width="200px"
                    height="18px"
                    loading={!run}
                  >
                    <Value>
                      {formatDistance({
                        distance: +(run?.completed_distance || 0),
                        unit: settingsContext.state.distanceUnit?.id || '',
                        biggerNumber: true,
                      })}
                    </Value>
                  </Skeleton>
                </div>
              </ValuesInfoWrapper>
            </RunInformationWrapper>
            <MapWrapper>
              <SpeedCanvas id="report-speed-chart" />
              <ElevationCanvas id="report-elevation-chart" />
              <InclinationCanvas id="report-inclination-chart" />
              <GTAttributeInformation>
                Report prepared by CDI (
                <a href="https://globaltrack.info">globaltrack.info</a>)
              </GTAttributeInformation>
            </MapWrapper>
          </InitialPage>
        </Page>
        <Page>
          <MapContainer>
            {
              run?.pipeline &&
              run?.trackingpoint_set ? (
                <Map
                  view_mode='editor'
                  controllers={[]}
                  modules={['run']}
                  hideControllers
                  hideWidgets
                  hideMapTypes
                  hideQubes
                  bounds={[]}
                />
              ) : null
            }
          </MapContainer>
        </Page>
        <div className="break" />
        {renderTrackingSheetPages(columns, allColumns, setColumns)}
        {renderTrackingPointPages(setMedia)}
      </PDFWrapper>
      <MediaModal
        open={!!media}
        media={media || ''}
        close={() => setMedia(undefined)}
      />
    </ReportContainer>
  );
};

export default Report;
