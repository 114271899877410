import { initialInspectionState } from './inspection.context';
import { InspectionContext, InspectionDispatchType } from './inspection.context.d';

/**
 *
 * @param state
 * @param action
 * @returns account context state
 */
export const reducer = (state: InspectionContext, action: InspectionDispatchType): InspectionContext => {
  switch (action.type) {
    case 'SET_MAP':
      return { ...state, map: action.data as InspectionContext['map'] };
    case 'TOGGLE_VIEW_MODE':
      return { ...state, view_mode: state.view_mode === 'editor' ? 'observer' : 'editor' } || action.data as InspectionContext['view_mode'];
    case 'SET_VIEW_MODE':
      return { ...state, view_mode: action.data as InspectionContext['view_mode'] };
    case 'SET_IS_FOLLOWING_PIG':
      return { ...state, following_pig: action.data as InspectionContext['following_pig'] };
    case 'SET_IS_FOLLOWING_LOGGED_USER':
      return { ...state, following_logged_user: action.data as InspectionContext['following_logged_user'] };
    case 'SET_PERMISSION_TYPE':
      return { ...state, permission_type: action.data as InspectionContext['permission_type'] };
    case 'SET_SELECTED_QUBE_MARKER': 
      return { ...state, selected_qube_marker: action.data as InspectionContext['selected_qube_marker'] };
    case 'SET_SELECTED_TIMEPIN': 
      return { ...state, selected_timepin: action.data as InspectionContext['selected_timepin'] };
    case 'SET_SELECTED_POINT': 
      return { ...state, selected_point: action.data as InspectionContext['selected_point'] };
    case 'SET_SELECTED_POINT_TRIGGERS': 
    return { ...state, selected_point_triggers: action.data as InspectionContext['selected_point_triggers'] };
    case 'SET_SELECTED_TRIGGER_PASSAGE': 
    return { ...state, selected_trigger_passage: action.data as InspectionContext['selected_trigger_passage'] };
    case 'SET_SELECTED_MEDIA':
      return { ...state, selected_media: action.data as InspectionContext['selected_media'] };
    case 'TOGGLE_MODAL':
        return { 
          ...state,
          modals: { ...initialInspectionState.modals, [action.data as string]: !state.modals[action.data as string] },
      };
    case 'CLOSE_MODALS':
      return { ...state, modals: { ...initialInspectionState.modals } };
    case 'SET_WEATHER_TILE':
      return { ...state, weather_tile: action.data as string | undefined };
    case 'SET_WIDGETS':
      return { ...state, widgets: action.data as InspectionContext['widgets'] };
    case 'SET_SLICKGRID':
      return { ...state, grid: action.data as any | undefined }; 
    case 'SET_TICK':
      return { ...state, tick: action.data as number };
    case 'SET_NO_CONNECTION_SINCE':
      return { ...state, no_connection_since: action.data as Date | undefined };
    case 'SET_USER_POSITION':
      return { ...state, user_position: action.data as InspectionContext['user_position'] };
    case 'TOGGLE_HAS_LABELS':
      return { ...state, has_labels: !state.has_labels };
    case 'SET_HAS_RULER':
      return { ...state, has_ruler: action.data as InspectionContext['has_ruler'] };
    // MOBILE
    case 'SET_SELECTED_POINT_TO_PASSAGE':
      return { 
        ...state,
        mobile: {
          ...state.mobile,
          selected_point_to_passage: action.data as InspectionContext['mobile']['selected_point_to_passage'],
          passage_tstamp: action.data ? new Date() : undefined,
        },
      };
    case 'SET_SELECTED_MOBILE_CLUSTER_MARKERS':
      return { 
        ...state,
        mobile: {
          ...state.mobile,
          selected_cluster_markers: action.data as InspectionContext['mobile']['selected_cluster_markers'],
        },
      };
    case 'SET_MOBILE_SELECTED_QUBE': 
    return { 
      ...state,
      mobile: {
        ...state.mobile,
        selected_qube: action.data as InspectionContext['mobile']['selected_qube'],
      },
    };
    case 'SET_MOBILE_SELECTED_POINT':
      return { 
        ...state,
        mobile: {
          ...state.mobile,
          selected_point: action.data as InspectionContext['mobile']['selected_point'],
        },
      };
      case 'SET_MOBILE_SELECTED_POINT_TO_GALLERY':
        return { 
          ...state,
          mobile: {
            ...state.mobile,
            selected_point_to_gallery: action.data as InspectionContext['mobile']['selected_point_to_gallery'],
          },
        };
        case 'SET_MOBILE_SELECTED_MEDIA_INDEX':
          return { 
            ...state,
            mobile: {
              ...state.mobile,
              selected_media_index: action.data as InspectionContext['mobile']['selected_media_index'],
            },
          };
          case 'SET_MOBILE_SELECTED_POINT_TO_TRIGGER':
            return { 
        ...state,
        mobile: {
          ...state.mobile,
          selected_point_to_trigger: action.data as InspectionContext['mobile']['selected_point_to_trigger'],
        },
      };
    case 'SET_MOBILE_SELECTED_TRIGGER':
      return { 
        ...state,
        mobile: {
          ...state.mobile,
          selected_trigger: action.data as InspectionContext['mobile']['selected_trigger'],
        },
      };
    case 'MOBILE_TOGGLE_INFORMATIONS_MODAL':
      return {  ...state, mobile: { ...state.mobile, informations_modal: !state.mobile.informations_modal }};
    case 'MOBILE_TOGGLE_SETTINGS_MODAL':
      return {  ...state, mobile: { ...state.mobile, settings_modal: !state.mobile.settings_modal }};
    case 'MOBILE_TOGGLE_WIDGETS_MODAL':
      return {  ...state, mobile: { ...state.mobile, widgets_modal: !state.mobile.widgets_modal }};
    case 'MOBILE_TOGGLE_SCHEDULE_MODAL':
      return {  ...state, mobile: { ...state.mobile, schedule_modal: !state.mobile.schedule_modal }};
    case 'MOBILE_QUBES_MODAL':
      return {  ...state, mobile: { ...state.mobile, qubes_modal: action.data as boolean }};
    default:
      throw new Error(`Unknown inspection reducer action ${action.type}`);
  }
};
