import { SetStateAction, useContext, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import * as unorm from 'unorm';
import { useChannelState } from "@rxdjango/react";
import { CustomerDevicesChannel } from 'app/modules/customer/customer.channels';
import AccountStore from 'app/modules/account/account.context';
import NotificationStore from 'app/modules/notification/notification.context';
import { AuthType } from 'app/modules/account/account.context.d';
import { CustomerType } from '../customer.interfaces';
import { QubeDeviceType } from 'app/modules/qube/qube.interfaces';
import { ConfirmationModalType } from 'app/components/confirmation-modal/confirmation-modal.d';
import Breadcrumbs from 'app/components/breadcrumbs/breadcrumbs';
import NavigationContainer from 'app/components/navigation-container/navigation-container';
import Card from 'app/components/devices/device-card/device-card';
import Skeleton from 'app/components/skeleton/skeleton';
import Tabs from 'app/components/tabs/tabs';
import Typograph from 'stories/type/typograph/typograph';
import IconButton from 'stories/base/buttons/icon-button/icon-button';
import Input from 'stories/base/forms/input/input';
import ProjectDevices from './components/project-devices-list/project-devices';
import CustomerDevicesBar from './components/customer-devices-bar/customer-devices-bar';
import ProjectDevicesBar from './components/project-devices-bar/project-devices-bar';
import ConfirmationModal from 'app/components/confirmation-modal/confirmation-modal';
import { handleConfirmDevices, handleRemoveDevices } from './devices.controller';
import {
  DevicesCustomerDeviceList,
  DevicesSection,
  DevicesSectionHeaderActions,
  DevicesPageContent,
  DevicesProjectSidebar,
  DevicesCustomerProjectList,
  SelectedCustomerDevicesTable,
} from './devices.page.style';
import Checkbox from 'stories/base/forms/checkbox/checkbox';

/**
 * 
 * @param amount 
 */
const getDragImage = (amount: number) => {
  // Create a canvas and draw a visual representation as a drag image
  const canvas = document.createElement('canvas');
  const squareSize = 50;
  const amountCircleSize = 10;

  canvas.width = squareSize + amountCircleSize / 2; // canvas width
  canvas.height = squareSize + amountCircleSize / 2; // canvas height
  const ctx = canvas.getContext('2d');
  
  if (!ctx) return;
  const borderRadius = 6;
  ctx.fillStyle = '#ffffff';
  ctx.strokeStyle = '#ffffff';
  
  // Create a rounded rectangle
  ctx.beginPath();
  ctx.moveTo(borderRadius, canvas.height - squareSize + amountCircleSize / 2);
  ctx.lineTo(squareSize - borderRadius, canvas.height - squareSize + amountCircleSize / 2);
  ctx.lineTo(squareSize - borderRadius, canvas.height - borderRadius);
  ctx.lineTo(borderRadius, canvas.height - borderRadius);

  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  
  // Load the device icon
  const deviceImage = new Image();
  deviceImage.src = '/assets/images/qube-icon.png';
  
  // Draw the device icon on the canvas
  const deviceImageSize = 30;
  ctx.drawImage(deviceImage, (squareSize - deviceImageSize) / 2, (canvas.height - squareSize + amountCircleSize / 2 + (squareSize - deviceImageSize)) / 2, deviceImageSize, deviceImageSize);
  
  ctx.fillStyle = '#f21e2b';
  ctx.beginPath();
  ctx.arc(canvas.width - amountCircleSize, amountCircleSize, amountCircleSize, 0, 2 * Math.PI);
  ctx.closePath();
  ctx.fill();


  ctx.fillStyle = '#ffffff';
  ctx.fillText(amount.toString(), canvas.width - 8 - amountCircleSize / 2, amountCircleSize + 3);

  // Create a new image from the canvas
  const dImage = new Image();
  dImage.src = canvas.toDataURL(); // Convert the content of the canvas to a data URL
  return dImage;
};

/**
 * 
 * @returns 
 */
const renderSelectedDevicesTable = (devices: QubeDeviceType[]) => {
  return (
    <SelectedCustomerDevicesTable>
      <thead>
        <tr>
          <th>Device Serial</th>
          <th>Original Project</th>
        </tr>
      </thead>

      <tbody>
        {devices.map((device, index) => (
          <tr key={device?.id || index}>
            <td>{device?.serial}</td>
            <td>{device?.project_name || '-'}</td>
          </tr>
        ))}
      </tbody>
    </SelectedCustomerDevicesTable>
  );
};

/**
 * 
 * @returns 
 */
const renderCard = (
  device: QubeDeviceType | undefined,
  selectedsCustomerDevices: number[],
  setSelectedsCustomerDevices: (action: SetStateAction<number[]>) => void,
  setSelectedsProjectDevices: (action: SetStateAction<{ [key: number]: number[] }>) => void,
  setDeviceDragging: (action: SetStateAction<QubeDeviceType | undefined>) => void,
  setSidebarOpened: (sidebarOpened: boolean) => void,
  customerSearch: string,
  dragImage: HTMLImageElement | null,
) => {
  if (device) {
    return (
      <Card
        id={device.id}
        stringPart={customerSearch}
        device={device}
        onClick={() => {}}
        dragImage={dragImage}
        onDrag={(device) => {
          setSidebarOpened(true);
          setDeviceDragging(device);
        }}
        onSelect={() => {
          setSelectedsProjectDevices({});
          setSelectedsCustomerDevices((prev) => {
            if (prev.includes(device.id)) {
              return prev.filter((id) => id !== device.id);
            }

            return [...prev, device.id];
          });
        }}
        selected={selectedsCustomerDevices.includes(device.id)}
      />
    );
  }

  return <Skeleton loading height="175px" />
};

/**
 * 
 * @returns 
 */
const Devices = () => {
  // router
  const { id }: { id: string } = useParams();
  const history = useHistory();

  // contexts
  const accountContext = useContext(AccountStore);
  const notificationContext = useContext(NotificationStore);

  const auth = accountContext.state.auth as AuthType;

  // Customer Channel
  const channel = new CustomerDevicesChannel(parseInt(id), auth.token);
  const { state: stateCustomer } = useChannelState<CustomerType>(channel);

  // states
  const [sidebarOpened, setSidebarOpened] = useState(false);
  const [projectSearch, setProjectSearch] = useState('');
  const [customerSearch, setCustomerSearch] = useState('');
  const [selectedsCustomerDevices, setSelectedsCustomerDevices] = useState<number[]>([]);
  const [selectedsProjectDevices, setSelectedsProjectDevices] = useState<{ [key: number]: number[] }>({});
  const [confirmationModal, setConfirmationModal] = useState<ConfirmationModalType>();
  const [deviceDragging, setDeviceDragging] = useState<QubeDeviceType>();
  
  // TODO: WHY THIS????
  const customer = useMemo(() => {
    const deepCopy = JSON.parse(JSON.stringify(stateCustomer || {}));
    return deepCopy;
  }, [stateCustomer]);

  const breadcrumbs = useMemo(() => {
    return [
      {
        id: '#1',
        text: 'Customer Devices',
        loading: false,
        href: `/customer/${id}/devices`,
      },
      {
        id: '#2',
        text: customer?.name || '-',
        loading: !customer?.id,
        href: `/customer/${id}/devices`,
      },
    ];
  }, [customer?.name, customer?.id]);

  const deployedDevices = useMemo(() => {
    const normalizedSearchText = unorm.nfkd(customerSearch).toLocaleLowerCase();
    const devices = customer?.qube_set?.filter((device) => !!device?.project_name) || []

    if (normalizedSearchText) {
      return devices.filter((device) => {
        const normalizedProjectName = unorm.nfkd(device?.project_name || '').toLocaleLowerCase();
        const normalizedSerial = unorm.nfkd(device?.serial || '').toLocaleLowerCase();
        return normalizedProjectName.includes(normalizedSearchText) || normalizedSerial.includes(normalizedSearchText);
      });
    }
    
    return devices;
  }, [customerSearch, customer?.qube_set]);

  const dragImage = useMemo(() => {
    if (selectedsCustomerDevices.length < 2) return null;
    return getDragImage(selectedsCustomerDevices.length) || null;
  }, [selectedsCustomerDevices]);

  const notDeployedDevices = useMemo(() => {
    const normalizedSearchText = unorm.nfkd(customerSearch).toLocaleLowerCase();
    const devices = customer?.qube_set?.filter((device) => !device?.project_name) || []

    if (normalizedSearchText) {
      return devices.filter((device) => {
        const normalizedProjectName = unorm.nfkd(device?.project_name || '').toLocaleLowerCase();
        const normalizedSerial = unorm.nfkd(device?.serial || '').toLocaleLowerCase();
        return normalizedProjectName.includes(normalizedSearchText) || normalizedSerial.includes(normalizedSearchText);
      });
    }
    
    return devices;
  }, [customerSearch, customer?.qube_set]);

  const allDevices = useMemo(() => {
    const normalizedSearchText = unorm.nfkd(customerSearch).toLocaleLowerCase();
    const devices = customer?.qube_set || []

    if (normalizedSearchText) {
      return devices.filter((device) => {
        const normalizedProjectName = unorm.nfkd(device?.project_name || '').toLocaleLowerCase();
        const normalizedSerial = unorm.nfkd(device?.serial || '').toLocaleLowerCase();
        return normalizedProjectName.includes(normalizedSearchText) || normalizedSerial.includes(normalizedSearchText);
      });
    }
    
    return devices;
  }, [customerSearch, customer?.qube_set]);

  const tabs = useMemo(() => {
    return {
      all: {
        id: 'all',
        text: 'All Devices',
        href: `/customer/${id}/devices`,
        quantity: customer?.qube_set?.length || 0,
      },
      deployed: {
        id: 'deployed',
        text: 'Deployed',
        href: `/customer/${id}/devices/deployed`,
        quantity: deployedDevices.length,
      },
      'not-deployed': {
        id: 'not-deployed',
        text: 'Not Deployed',
        href: `/customer/${id}/devices/not-deployed`,
        quantity: notDeployedDevices.length,
      },
    };
  }, [deployedDevices, notDeployedDevices]);

  const selectedTab = useMemo(() => {
    return tabs[history?.location.pathname.split('/')[4]] || tabs.all
  }, [tabs, history.location.pathname]);

  const devicesDic = useMemo(() => {
    return customer?.qube_set?.reduce((acc, device) => {
      if (!device) {
        return acc;
      }
      
      return {
        ...acc,
        [device.id]: device,
      };
    }, {});
  }, [customer?.qube_set]);

  const projectsDic = useMemo(() => {
    return customer?.project_set?.reduce((acc, project) => {
      if (!project) {
        return acc;
      }
      
      return {
        ...acc,
        [project.id]: project,
      };
    }, {});
  }, [customer?.project_set]);
  
  const projectDevicesDic = useMemo(() => {
    return customer?.project_set?.reduce((acc, project) => {
      if (!project) {
        return acc;
      }

      return {
        ...acc,
        [project.id]: project?.device_set?.reduce((accb: QubeDeviceType[], device) => {
          if (!device || !device.qubedevice) {
            return accb;
          }

          return [...accb, devicesDic?.[device.qubedevice]];
        }, []) || [],
      };
    }, {}) as { [key: number]: QubeDeviceType[] };
  }, [devicesDic, customer?.project_set]);

  const projectSearchedDevices = useMemo(() => {
    const normalizedSearchText = unorm.nfkd(projectSearch).toLocaleLowerCase();

    if (customer?.project_set) {
      return customer.project_set.filter((project) => {
        if (!project) return false;

        const normalizedProjectName = unorm.nfkd(project.name || '').toLocaleLowerCase();
        const normalizedObjectSerials = projectDevicesDic?.[project.id]?.map((device) => {
          return unorm.nfkd(device.serial || '').toLocaleLowerCase();
        }) || [];

        const includeSerials = normalizedObjectSerials.length && normalizedObjectSerials.some((string) => string.includes(normalizedSearchText));
        return normalizedProjectName.includes(normalizedSearchText) || (includeSerials);
      });
    }

    return [];
  }, [projectSearch, customer?.project_set, projectDevicesDic]);

  return (
    <NavigationContainer>
      <div style={{ display: 'grid', gridTemplateRows: 'auto 1fr' }}>
        <Breadcrumbs routes={breadcrumbs} />
        <DevicesPageContent>
          <DevicesProjectSidebar $loading={!customer?.id} $opened={sidebarOpened}>
            <button onClick={() => setSidebarOpened(true)}>
              <span className="material-symbols-rounded">grid_view</span>
              Projects
            </button>
            
            <header>
              <Typograph type="subtitle2" text="Projects" />
              <IconButton styleType="transparent" icon="close" onClick={() => setSidebarOpened(false)} />
            </header>

            <div>
              <Input
                id="project-search"
                icon="search"
                iconAlignment="right"
                value={projectSearch}
                placeholder="Quick search"
                onChange={(e) => setProjectSearch(e.target.value)}
              />
            </div>

            <DevicesCustomerProjectList>
              {projectSearchedDevices.map((project, index) => (
                <li key={project?.id || index}>
                  <ProjectDevices
                    disabled={!project || !selectedsCustomerDevices.length}
                    search={projectSearch}
                    project={project}
                    devices={projectDevicesDic?.[project?.id] || []}
                    selecteds={Object.values(selectedsProjectDevices).flat()}
                    selectedCustomerDevices={selectedsCustomerDevices.map((id) => devicesDic?.[id])}
                    deviceDragging={deviceDragging}
                    onDropDevice={(event) => {
                      event.preventDefault();
                      const deviceId = parseInt(event.dataTransfer.getData('text'));
                      const isMultiDragDrop = selectedsCustomerDevices.length > 1;

                      if (!isMultiDragDrop && projectDevicesDic[project.id].findIndex((device) => device.id === deviceId) !== -1) {
                        notificationContext.dispatch({
                          type: 'SET_TOAST',
                          data: {
                            type: 'error',
                            text: `Qube Device selected is already assigned to ${project.name?.toUpperCase()}.`
                          },
                        });

                        return;
                      }

                      if (!isMultiDragDrop && project && deviceId && devicesDic?.[deviceId])  {
                        handleConfirmDevices(auth.token, project.id, [deviceId]);
                        setDeviceDragging(undefined);
                        notificationContext.dispatch({
                          type: 'SET_TOAST',
                          data: {
                            type: 'success',
                            text: `Qube Device have been assigned to ${project.name?.toUpperCase()}.`
                          },
                        });
                      }

                      if (isMultiDragDrop && project && deviceId && devicesDic?.[deviceId])  {
                        handleConfirmDevices(auth.token, project.id, selectedsCustomerDevices);
                        setDeviceDragging(undefined);
                        notificationContext.dispatch({
                          type: 'SET_TOAST',
                          data: {
                            type: 'success',
                            text: `Qube Devices have been assigned to ${project.name?.toUpperCase()}.`
                          },
                        });
                      }
                    }}
                    onDragLeaveDevices={() => {}}
                    onSelectDevice={(deviceId) => {
                      setSelectedsCustomerDevices([]);
                      setSelectedsProjectDevices((prev) => {
                        if (prev[project.id]?.includes(deviceId)) {
                          return {
                            ...prev,
                            [project.id]: prev[project.id]?.filter((id) => id !== deviceId),
                          };
                        }

                        return {
                          ...prev,
                          [project.id]: [...(prev[project.id] || []), deviceId],
                        };
                      });
                    }}
                    onRemoveDevice={(deviceId) => {
                      setConfirmationModal({
                        hide: false,
                        title: 'Remove device from project',
                        text: 'Are you sure you want to unassign the device from the project?',
                        confirmationText: 'Remove',
                        cancelationText: 'Cancel',
                        onConfirm: () => {
                          setConfirmationModal(undefined);
                          handleRemoveDevices(auth.token, project.id, [deviceId]);

                          notificationContext.dispatch({
                            type: 'SET_TOAST',
                            data: {
                              type: 'success',
                              text: `Qube Device has been unassigned from ${project.name?.toUpperCase()}.`
                            },
                          });
                          
                          setSelectedsProjectDevices((prev) => {
                            return {
                              ...prev,
                              [project.id]: [],
                            };
                          });
                        },
                        onCancel: () => setConfirmationModal(undefined),
                      });
                    }}
                    onAssignDevices={() => {
                      setConfirmationModal({
                        hide: false,
                        title: 'Add devices to project',
                        text: 'Are you sure you want to assign the selected devices to the project?',
                        confirmationText: 'Confirm',
                        cancelationText: 'Cancel',
                        children: renderSelectedDevicesTable(selectedsCustomerDevices.map((id) => devicesDic?.[id])),
                        onConfirm: () => {
                          setConfirmationModal(undefined);
                          handleConfirmDevices(auth.token, project.id, selectedsCustomerDevices);

                          notificationContext.dispatch({
                            type: 'SET_TOAST',
                            data: {
                              type: 'success',
                              text: `Qube Device${selectedsCustomerDevices.length > 1 ? 's' : ''} have been assigned to ${project.name?.toUpperCase()}.`
                            }
                          });

                          setSelectedsCustomerDevices([]);
                        },
                        onCancel: () => setConfirmationModal(undefined),
                      });
                    }}
                  />
                </li>
              ))}
            </DevicesCustomerProjectList>
          </DevicesProjectSidebar>
          
          <DevicesSection id="devices">
            <header>
              <Tabs routes={Object.values(tabs)} selected={selectedTab} />
              <DevicesSectionHeaderActions>
                <Input
                  id="customer-device-search"
                  icon="search"
                  iconAlignment="right"
                  value={customerSearch}
                  placeholder="Search the device"
                  onChange={(e) => setCustomerSearch(e.target.value)}
                />

                <Checkbox
                  checked={selectedsCustomerDevices.length === customer?.qube_set?.length}
                  onClick={() => {
                    if (selectedsCustomerDevices.length === customer?.qube_set?.length) {
                      setSelectedsCustomerDevices([]);
                    } else {
                      setSelectedsCustomerDevices(customer?.qube_set?.map((device) => device?.id) || []);
                    }
                  }}
                  text='Select all devices'
                />
              </DevicesSectionHeaderActions>
            </header>
            
            {
              selectedTab?.id === tabs.all.id ? (
                <DevicesCustomerDeviceList $opened={sidebarOpened}>
                  {allDevices?.map((device, index) => (
                    <li key={device?.id || index}>
                      {renderCard(
                        device,
                        selectedsCustomerDevices,
                        setSelectedsCustomerDevices,
                        setSelectedsProjectDevices,
                        setDeviceDragging,
                        setSidebarOpened,
                        customerSearch,
                        dragImage,
                      )}
                    </li>
                  ))}
                </DevicesCustomerDeviceList>
              ) : null
            }

            {
              selectedTab.id === tabs.deployed.id ? (
                <DevicesCustomerDeviceList $opened={sidebarOpened}>
                  {deployedDevices?.map((device, index) => (
                    <li key={device?.id || index}>
                      {renderCard(
                        device,
                        selectedsCustomerDevices,
                        setSelectedsCustomerDevices,
                        setSelectedsProjectDevices,
                        setDeviceDragging,
                        setSidebarOpened,
                        customerSearch,
                        dragImage,
                      )}
                    </li>
                  ))}
                </DevicesCustomerDeviceList>
              ) : null
            }

            {
              selectedTab.id === tabs['not-deployed'].id ? (
                <DevicesCustomerDeviceList $opened={sidebarOpened}>
                  {notDeployedDevices?.map((device, index) => (
                    <li key={device?.id || index}>
                      {renderCard(
                        device,
                        selectedsCustomerDevices,
                        setSelectedsCustomerDevices,
                        setSelectedsProjectDevices,
                        setDeviceDragging,
                        setSidebarOpened,
                        customerSearch,
                        dragImage,
                      )}
                    </li>
                  ))}
                </DevicesCustomerDeviceList>
              ) : null
            }
          </DevicesSection>
        </DevicesPageContent>

        {
          selectedsCustomerDevices.length ? (
            <CustomerDevicesBar
              selecteds={selectedsCustomerDevices}
              projects={customer?.project_set || []}
              onDeselectAll={() => setSelectedsCustomerDevices([])}
              onAddDevicesToProject={(projectId) => {
                setConfirmationModal({
                  hide: false,
                  title: 'Add devices to project',
                  text: 'Are you sure you want to assign the selected devices to the project?',
                  confirmationText: `Confirm`,
                  cancelationText: 'Cancel',
                  children: renderSelectedDevicesTable(selectedsCustomerDevices.map((id) => devicesDic?.[id])),
                  onConfirm: () => {
                    setConfirmationModal(undefined);
                    handleConfirmDevices(auth.token, projectId, selectedsCustomerDevices);
                    notificationContext.dispatch({
                      type: 'SET_TOAST',
                      data: {
                        type: 'success',
                        text: `Qube Device${selectedsCustomerDevices.length > 1 ? 's' : ''} have been assigned from the ${projectsDic?.[projectId]?.name.toUpperCase()}.`
                      },
                    });
                    setSelectedsCustomerDevices([]);
                  },
                  onCancel: () => setConfirmationModal(undefined),
                });
              }}
            />
          ) : null
        }
        
        {
          Object.values(selectedsProjectDevices).flat().length ? (
            <ProjectDevicesBar
              selecteds={Object.values(selectedsProjectDevices).flat()}
              onDeselectAll={() => setSelectedsProjectDevices({})}
              onRemoveDevices={() => {
                setConfirmationModal({
                  hide: false,
                  title: 'Remove devices from project',
                  text: 'Are you sure you want to remove the selected devices from the project?',
                  confirmationText: 'Remove',
                  cancelationText: 'Cancel',
                  onConfirm: () => {
                    setConfirmationModal(undefined);
                    
                    for (const projectId in selectedsProjectDevices) {
                      if (selectedsProjectDevices[projectId].length) {
                        const devices = selectedsProjectDevices[projectId];
                        handleRemoveDevices(auth.token, parseInt(projectId), devices);
                        notificationContext.dispatch({
                          type: 'SET_TOAST',
                          data: {
                            type: 'success',
                            text: `Qube Device${devices.length > 1 ? 's' : ''} have been unassigned from the projects.`,
                          },
                        });
                      }
                    }

                    setSelectedsProjectDevices({});
                  },
                  onCancel: () => setConfirmationModal(undefined),
                });
              }}
            />
          ) : null
        }

        {
          confirmationModal ? (
            <ConfirmationModal
              hide={false}
              title={confirmationModal.title}
              text={confirmationModal.text}
              confirmationText={confirmationModal.confirmationText}
              cancelationText={confirmationModal.cancelationText}
              onConfirm={confirmationModal.onConfirm}
              onCancel={confirmationModal.onCancel}
              hideCancelButton={false}
            >
              {confirmationModal.children}
            </ConfirmationModal>
          ) : null
        }
      </div>
    </NavigationContainer>
  )
};

export default Devices;
